import { LOCALE_TW, LOCALE_EN } from './constants'
import otaClient from '@crowdin/ota-client'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { writeFileSync } from 'fs'
import path from 'path'
import { notify } from '../error-notifier'
import { i18n } from 'next-i18next'

export const isLocaleTW = (lang: string) => lang === LOCALE_TW
export const isLocaleEN = (lang: string) => lang === LOCALE_EN

const NAMESPACE_CROWDIN = 'crowdin' as const
const DEFAULT_ALL_HASH = '23a175e04f337d14f15d659gmed'

export const refreshI18nResourceBundle = async ({
  locale,
  rootDir,
  namespaces = [],
  distributionHash = DEFAULT_ALL_HASH,
}: {
  locale: string
  rootDir: string
  namespaces?: string[]
  distributionHash?: string
}) => {
  try {
    let translations = {}

    const crowdinOtaClient = new otaClient(distributionHash)

    const contents = await crowdinOtaClient.getLanguageTranslations(locale)
    contents.forEach(({ content }) => {
      translations = { ...content, ...translations }
    })

    if (i18n) {
      writeFileSync(
        path.resolve(rootDir, 'public/locales', 'en', `${NAMESPACE_CROWDIN}.json`),
        JSON.stringify({}),
      )
      writeFileSync(
        path.resolve(rootDir, 'public/locales', 'zh-TW', `${NAMESPACE_CROWDIN}.json`),
        JSON.stringify({}),
      )
      i18n?.removeResourceBundle('en', NAMESPACE_CROWDIN)
      i18n?.removeResourceBundle('zh-TW', NAMESPACE_CROWDIN)
      i18n?.addResourceBundle(locale, NAMESPACE_CROWDIN, translations, true, true)
    } else {
      writeFileSync(
        path.resolve(rootDir, 'public/locales', locale, `${NAMESPACE_CROWDIN}.json`),
        JSON.stringify(translations),
      )
    }
    return await serverSideTranslations(locale, [...namespaces, NAMESPACE_CROWDIN])
  } catch (err) {
    notify({ err, context: { message: 'Refresh i18n error.' } })
  }
}
